<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="12" md="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">
                      {{ userData.name }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ userData.adress }}
                  </p>
                  <p class="card-text mb-25">{{ userData.cityId }}</p>
                  <p class="card-text mb-0">
                    {{ userData.state }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Code
                    <span class="invoice-number"> # {{ userData.id }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Date Create:</p>
                    <p class="invoice-date">{{ userData.createdAt }}</p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Date Update:</p>
                    <p class="invoice-date">{{ userData.createdAt }}</p>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Customers</span>
        </template>
        <office-edit-employees :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Employees</span>
        </template>
      </b-tab>
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Servicices</span>
        </template>
      </b-tab>
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Sales</span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BAlert,
  BLink,
} from "bootstrap-vue";

import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import OfficeEditEmployees from "./OfficeEditEmployees.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BImg,

    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OfficeEditEmployees,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data;
        console.log(userData);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
